import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import { Container } from "theme-ui";
import { Testimonial, TypeformLink, Video } from "@swizec/gatsby-theme-course-platform";
export const pageQuery = graphql`
  query {
    Test2: file(relativePath: { eq: "Test2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Container mdxType="Container">
      <h2 {...{
        "id": "thanks-for-buying-react-for-data-visualization-span-roleimg-aria-labelheart️span"
      }}>{`Thanks for buying React for Data Visualization `}<span role="img" aria-label="heart">{`❤️`}</span></h2>
      <Video id="-t27yOeVEik" mdxType="Video" />
      <p>{`Hey thanks again for buying React for Data Visualization. I really appreciate it and I hope it helps you on your journey.`}</p>
      <p>{`Wanna help me make React for Data Visualization even better?`}</p>
      <p>{`Suggest a chapter I should add?`}</p>
      <p>{`Answer 2 quick questions below. Only takes a minute and makes a huge difference. Plus you're gonna be one of my favorite humans.`}</p>
      <TypeformLink url="https://swizecteller.typeform.com/to/gzZ4up" mdxType="TypeformLink" />
      <p>{`Then check your email for access and login instructions. You'll need to set a password for your new account :)`}</p>
      <Testimonial quote="Great read with solid foundations to get you started crawling with react and up to a sprint!" image={props.data.Test2.childImageSharp.fluid.src} name="MΛHDI" company="Creator of Gyroscope" mdxType="Testimonial" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      